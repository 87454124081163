import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision 2.7 as a Windows Service",
  "path": "/Frequently_Asked_Question/InstarVision_Windows_Service/",
  "dateChanged": "2021-09-22",
  "author": "Mike Polinowski",
  "excerpt": "Hello, is there a way to run the instar Vision 2.7 software as a service on a Windows Server 2019? Otherwise I have the problem that the recording does not run when the server has restarted at night.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - InstarVision 2.7 as a Windows Service' dateChanged='2021-09-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Hello, is there a way to run the instar Vision 2.7 software as a service on a Windows Server 2019? Otherwise I have the problem that the recording does not run when the server has restarted at night.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/InstarVision_Windows_Service/' locationFR='/fr/Frequently_Asked_Question/InstarVision_Windows_Service/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "instarvision-27-as-a-windows-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instarvision-27-as-a-windows-service",
        "aria-label": "instarvision 27 as a windows service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision 2.7 as a Windows Service`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Hello, is there a way to run the instar Vision 2.7 software as a service on a Windows Server 2019? Otherwise I have the problem that the recording does not run when the server has restarted at night.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The InstarVision 2.7 Software cannot directly be run as a service under Windows 10, Server 2016 and newer. But you can use a free third-party software to register the application binary as a background service. One of those software is called NSSM:`}</p>
    <p><a parentName="p" {...{
        "href": "http://nssm.cc/download"
      }}>{`Download NSSM`}</a></p>
    <p>{`Download the container and unzip it to your C partition:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/80f41283570c9cf52e649feeee177e10/bb3ba/InstarVision_System_Service_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADD0lEQVQ4y32Sa0/TUBjHqw6ioqDSimGsF7qlbGZjLUzCLWgkm44EA0t0AomXdyZqfOULxRB47Ufw8imMr/gCchvoiwHOwNpzCh1Z17VzjF5mWhuj0fjPyZNz+z3nf55zkMXFxVzu29paZmV5eT2zvrq6tvRpKZPJbGysf/7yZX1jY2l5ZXV1TdeN+l9CeEE4OChAURQBhCLc2dkpFuUjR3qtZuh6rVo9PDw0DcPQ/5Bpmkgu93Vza3Mzm93a2t7e3uZ5fm9vTxSh6GTKf81JABQkSYJQgkAAAoTQPkkUK5UKUihI4p4oSZIsl8rlsqZphvEPh/8Uks1mpUJB0zRFUcq2FFmW5VJJURTFifv7+5Ik2SN7XCqV5GKxqKqqDefzuxAIPC8IgsALPAAgz/MAQAAAz/MQgN28LQghAIIA7D28wEMIqtUqoiiKZVmGoRuG6UTDODqy288CGfa0aZp/e7YsCylIhaIsl9Wyqqkl25qi6rpqGlqlompapVJRVVXTNNM0Lcv6GS3Lcu+sat91XXdS2dnszoeP9Tfv63XLqNkP9r+CvXj++OGDh1NTU+l0+m46nZ6engyGJnz4zWTyRiIRT8THxsbGx8fTjmZmZlKp1Nt3713b7VhT8/kL51paTp9uQo4dO3H8uOdMk6f5bKOjhsZGj8fj9hsaTp06iSDIoyfP6vW6/Ulwyu+naR+Od3eHOY4NBAJ+mqY7O1mW5Vi2q6srHI4EAgGGYfx+P8MwbW1tr2ZnXTjMxUjCd/HipZEhbniwjyBIkiQJggiHI729vcFgMBqNUhRFOAs0TaMotrCw4MLRaJiiqI4OPHE9dnWknyTdfSzLDQwMhkKhWCxGURTpyIHR+fl5F2ZClymS9Ho7hga44aH+rmDQT9MEQbAs29fXxzBMD8f9DmMYNjc358Ioira3t7ei2LVhbqA/5iUonCR9Pl+kOxKLXbHhnh6CIHAc/wW/nH3pVhtF0Q6vt7UVS926du/+9OtO5mk4OpyIT05M3Ll9O5lMplKp0dHReDweiURIksQw7JftHyfNef4xQwBbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80f41283570c9cf52e649feeee177e10/e4706/InstarVision_System_Service_01.avif 230w", "/en/static/80f41283570c9cf52e649feeee177e10/d1af7/InstarVision_System_Service_01.avif 460w", "/en/static/80f41283570c9cf52e649feeee177e10/7f308/InstarVision_System_Service_01.avif 920w", "/en/static/80f41283570c9cf52e649feeee177e10/36c14/InstarVision_System_Service_01.avif 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/80f41283570c9cf52e649feeee177e10/a0b58/InstarVision_System_Service_01.webp 230w", "/en/static/80f41283570c9cf52e649feeee177e10/bc10c/InstarVision_System_Service_01.webp 460w", "/en/static/80f41283570c9cf52e649feeee177e10/966d8/InstarVision_System_Service_01.webp 920w", "/en/static/80f41283570c9cf52e649feeee177e10/eb6f0/InstarVision_System_Service_01.webp 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80f41283570c9cf52e649feeee177e10/81c8e/InstarVision_System_Service_01.png 230w", "/en/static/80f41283570c9cf52e649feeee177e10/08a84/InstarVision_System_Service_01.png 460w", "/en/static/80f41283570c9cf52e649feeee177e10/c0255/InstarVision_System_Service_01.png 920w", "/en/static/80f41283570c9cf52e649feeee177e10/bb3ba/InstarVision_System_Service_01.png 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/80f41283570c9cf52e649feeee177e10/c0255/InstarVision_System_Service_01.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the executable to your `}<strong parentName="p">{`PATH`}</strong>{` variables:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/def6e42df02dce0172e38426130a88c6/07e9f/InstarVision_System_Service_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB40lEQVQozz3RTYvTQADG8Vw9iEIvetjTgnG9CV78KILgF/SwB5Ftu91DEel6EARNajdv3bzNZCaTaSbzkkkmI62rv/sfHnicF6778uKV67oXJ67rnp+fn52dXV5eaq0ppaQmFazqmkgp9YlSSgiRpplzvbxZr78slov5YrFara6X11cnnufnWV4WJQAgSZIKVlJKcyKE2Cd7z/OdRpjJWilFTYiSR4ILrTVCCFXITBMh5G63y7Occz4ZY63ttY6j6Ocvz6FXqw5UXErWMimFlIIxBiGsKoQg6vseYxwFYRSGURhhhMdx7Lpuv9/7261jETJ9zzlnjCmlxnGcpolzjjEGFSJNAwGI4yQMQkoaM1kz2YYewjD0PM+x1hpjOs5pQ4UQ2hg9DIyx4+ogPJAG4zpLovT+ng+DHZS1tmNtURRBEPyNRyFExzoplaAH1raHwwEilF0taZqmEM9/3N+uvycfP332yHzHbwPcSwEAPMbDMGitrbWTterrpo6TrCwhACmAWZYDANOK5GkOd0FY4G1c+Hex7/ubzbeH2Yyxtm0ppRWEEMCyPD5E6iMIISyLvMj3WRaHd2kS7X5vX795+2T2zGlbLmQ//WcfHD/7R0qplOKcKynzonj3/sPT2fNHj2d/AHeHCL586lnYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/def6e42df02dce0172e38426130a88c6/e4706/InstarVision_System_Service_02.avif 230w", "/en/static/def6e42df02dce0172e38426130a88c6/d1af7/InstarVision_System_Service_02.avif 460w", "/en/static/def6e42df02dce0172e38426130a88c6/7f308/InstarVision_System_Service_02.avif 920w", "/en/static/def6e42df02dce0172e38426130a88c6/6557b/InstarVision_System_Service_02.avif 1301w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/def6e42df02dce0172e38426130a88c6/a0b58/InstarVision_System_Service_02.webp 230w", "/en/static/def6e42df02dce0172e38426130a88c6/bc10c/InstarVision_System_Service_02.webp 460w", "/en/static/def6e42df02dce0172e38426130a88c6/966d8/InstarVision_System_Service_02.webp 920w", "/en/static/def6e42df02dce0172e38426130a88c6/77607/InstarVision_System_Service_02.webp 1301w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/def6e42df02dce0172e38426130a88c6/81c8e/InstarVision_System_Service_02.png 230w", "/en/static/def6e42df02dce0172e38426130a88c6/08a84/InstarVision_System_Service_02.png 460w", "/en/static/def6e42df02dce0172e38426130a88c6/c0255/InstarVision_System_Service_02.png 920w", "/en/static/def6e42df02dce0172e38426130a88c6/07e9f/InstarVision_System_Service_02.png 1301w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/def6e42df02dce0172e38426130a88c6/c0255/InstarVision_System_Service_02.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now be able to start the program from Powershell:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/ffe34/InstarVision_System_Service_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAg0lEQVQI123Myw7CMAxE0exLLIeCUjUPk3FMV+X/Pw81q6JyFqO7Gtd7t2HbNhEppchQSqm1Hjv6L7csCxreZimldQCwf3YAZqaqOWdVrbXmC7em9GoAmojE5+MeAjMTEfkDEU3TNNLfTrz3IQSnqmYmIlDVrkCLMfJJGHfhFzPP8/wFvP4X4BpYrbAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/e4706/InstarVision_System_Service_03.avif 230w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/d1af7/InstarVision_System_Service_03.avif 460w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/7f308/InstarVision_System_Service_03.avif 920w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/11906/InstarVision_System_Service_03.avif 1055w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/a0b58/InstarVision_System_Service_03.webp 230w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/bc10c/InstarVision_System_Service_03.webp 460w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/966d8/InstarVision_System_Service_03.webp 920w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/5ad49/InstarVision_System_Service_03.webp 1055w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/81c8e/InstarVision_System_Service_03.png 230w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/08a84/InstarVision_System_Service_03.png 460w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/c0255/InstarVision_System_Service_03.png 920w", "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/ffe34/InstarVision_System_Service_03.png 1055w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ce90fe31e35d0637c24bad3b5dd08cd/c0255/InstarVision_System_Service_03.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start service installer with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nssm install instarvision`}</code>{` and add the path to the InstarVision executable:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f522db7da27326a0833ae7dd880fad7/914c7/InstarVision_System_Service_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA2ElEQVQY042OzVLCMBSFK7CjRXSUhUKb/qSCrwCt77/vKpfO9CY3bTphYnkDB9QdiHfOfKvznbleyvMk4yyOWZyE0Q9fXpfLVbQK2cWEETs3U+9tvVlv3nnOM54nafbNMGKz+7kfzK5l6gdPzwtvv68BgEgTkdYnElHbtttdMRpP/piYPzx6Wuuu6xCxaRqllJRSKWUPtig/7kbja/LUD04yIkopAUAIAQB1XSOitXZXlLdlKaUx5vP3nHPH4TgMw1me3JD7vkdEY4xzjoiqqhJCaK3Lf7z9BTVNg2VHN+l1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f522db7da27326a0833ae7dd880fad7/e4706/InstarVision_System_Service_04.avif 230w", "/en/static/3f522db7da27326a0833ae7dd880fad7/d1af7/InstarVision_System_Service_04.avif 460w", "/en/static/3f522db7da27326a0833ae7dd880fad7/7f308/InstarVision_System_Service_04.avif 920w", "/en/static/3f522db7da27326a0833ae7dd880fad7/6a69c/InstarVision_System_Service_04.avif 978w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f522db7da27326a0833ae7dd880fad7/a0b58/InstarVision_System_Service_04.webp 230w", "/en/static/3f522db7da27326a0833ae7dd880fad7/bc10c/InstarVision_System_Service_04.webp 460w", "/en/static/3f522db7da27326a0833ae7dd880fad7/966d8/InstarVision_System_Service_04.webp 920w", "/en/static/3f522db7da27326a0833ae7dd880fad7/0e613/InstarVision_System_Service_04.webp 978w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f522db7da27326a0833ae7dd880fad7/81c8e/InstarVision_System_Service_04.png 230w", "/en/static/3f522db7da27326a0833ae7dd880fad7/08a84/InstarVision_System_Service_04.png 460w", "/en/static/3f522db7da27326a0833ae7dd880fad7/c0255/InstarVision_System_Service_04.png 920w", "/en/static/3f522db7da27326a0833ae7dd880fad7/914c7/InstarVision_System_Service_04.png 978w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f522db7da27326a0833ae7dd880fad7/c0255/InstarVision_System_Service_04.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a name and description and set the `}<strong parentName="p">{`Startup Type`}</strong>{` to `}<strong parentName="p">{`Automatic`}</strong>{` if you want InstarVision to be started automatically when you boot your server:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/d30ee/InstarVision_System_Service_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA9UlEQVQY04XOSU+DQACG4Sm9CXRxqa22MCwaa4EY9G7B/38hIeHENgsDgQAJJHDz4JVI8l6ffB/Q9BdV06ECoapKUIGKJslwf3g+nuSTBCeTZCjJiqa/gg/btj+/TMsyTPNiGIZpvV+Mt/N5e3vHC6Igria74YXD0xEwxtI0zbIMY0wIwRgjhKuqctwfsODWm+0k5gXxfvcIMMaU0jiOEUJ5nmdZRghpmmYWP+z2IE1TSilCiDFWFEXf98MwjON4dRzALWcwQihJEkJI27aMsbIs67ruus5x3fnlMAyDIIii6O+853m+78dx9H11Ftxytd78g38B1LeFrvbYxdUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/e4706/InstarVision_System_Service_05.avif 230w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/d1af7/InstarVision_System_Service_05.avif 460w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/7f308/InstarVision_System_Service_05.avif 920w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/b84c9/InstarVision_System_Service_05.avif 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/a0b58/InstarVision_System_Service_05.webp 230w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/bc10c/InstarVision_System_Service_05.webp 460w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/966d8/InstarVision_System_Service_05.webp 920w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/58b01/InstarVision_System_Service_05.webp 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/81c8e/InstarVision_System_Service_05.png 230w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/08a84/InstarVision_System_Service_05.png 460w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/c0255/InstarVision_System_Service_05.png 920w", "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/d30ee/InstarVision_System_Service_05.png 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7762a293ed5aa0a78c634c3cc8eb8f51/c0255/InstarVision_System_Service_05.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define the user you want the service to use to execute the program:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ec498765cd4c4fb6e05d4073c460794/339e3/InstarVision_System_Service_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA6klEQVQY04WPvW6DMBSF79gm/KVL24SYlLTPECBEfZVkN3lAJF6AxQsSC43tFhsjdURmjaDq1Ih++nR1lqOjC/721d++IYSQ57lrb40GH5+elyv3J98UeZvNiw9xfIgP79F+H4bRLoiC4Qa7IFyu3Lv7mWFac8P862xuWLYDjLLLx0XUUsqmaRqllBCi6zqMMQA4i4VhWjc1LRsYpZxzIcTnCOecMaa1/i0/TJUppVVVCSG+Ruq65pzrXmOcAIA9XZYjbdt+jyilpJR93yfJ+f9lQkie54SQsiwJIWmaZllWFMXxdBqWnamfry8UhdXj5e8VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ec498765cd4c4fb6e05d4073c460794/e4706/InstarVision_System_Service_06.avif 230w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/d1af7/InstarVision_System_Service_06.avif 460w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/7f308/InstarVision_System_Service_06.avif 920w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/5d0a8/InstarVision_System_Service_06.avif 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ec498765cd4c4fb6e05d4073c460794/a0b58/InstarVision_System_Service_06.webp 230w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/bc10c/InstarVision_System_Service_06.webp 460w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/966d8/InstarVision_System_Service_06.webp 920w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/56bc3/InstarVision_System_Service_06.webp 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ec498765cd4c4fb6e05d4073c460794/81c8e/InstarVision_System_Service_06.png 230w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/08a84/InstarVision_System_Service_06.png 460w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/c0255/InstarVision_System_Service_06.png 920w", "/en/static/3ec498765cd4c4fb6e05d4073c460794/339e3/InstarVision_System_Service_06.png 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ec498765cd4c4fb6e05d4073c460794/c0255/InstarVision_System_Service_06.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can check the rest of the NSSM tabs to see if there are defaults that you need to change and then click on `}<strong parentName="p">{`Install Service`}</strong>{`. Restart your system and verify that the service was started:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "798px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b467ce6abf248cefeaa70bb871891ac/898f6/InstarVision_System_Service_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACOUlEQVQoz42SyWsUURCH+68ZOyAxZvK2nsYclCGJB0kg/1EOOYROJkEYsyGI6Emv4lm9iNcoaJaZ7pnet3S/9+b19qQTDXjzO9SpvvpVQSkZ5bOZqOu6KqumaeR/0DRNXddN0yij0ejs52hkmeZ0bE6jjGZBnFyzvK4qzjmllN/BWJ7nlDJKKWNtVcbW5Go0ntj2lTUJozhN0zhJ4jThrO0PgkCIoo1qmqpuirISRRGEkSiKLMuVsizrqiyK8sfF5Pz818X5hed5ruNkWf7p8xfD2D0+Oto/OHhu7L0+On5zcvrqxeHQ2Ht7cvry8FCJotj3fdf1fM93XddxnZvdqJRysH/Q7S6trqwsP1remJ/f7HafPVhYf7i4vrC4CeDG/XklSRLP96Modtq0jDNe17UQQko5HA4hRE/X1vr9/qqu9zFe0Xp9TB4D2Mf4CUJKFIae63HOTdPy/eA2tihKKaVhGJ1OB0K0BEAXAoCxpusQ4yWIkKZ1AVCy6yxJkizLGONCCMYY57OqqqSUOzs7qqoSQiCEEECMUE/TCMYQAA0TBKGSJKllWpY1SdO0FKJsKThnUsrdXWNubk7TehhjhBDG7RSEEIRQ07RWppTmlAohLNuOxSyO4jiOwzAsimJ7e1tVVfQXQoiu67hNhoSQP/J1mvKqYpdX5YePtx90u/ZgMLjXuUcwAQCC9mTc6/X+kQPf/3525gTB5ddv03fvnSAwx2PbtqfT6dbWlqqqGGNwA0a4dRC6k38D/t/mKhfGNCsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b467ce6abf248cefeaa70bb871891ac/e4706/InstarVision_System_Service_07.avif 230w", "/en/static/0b467ce6abf248cefeaa70bb871891ac/d1af7/InstarVision_System_Service_07.avif 460w", "/en/static/0b467ce6abf248cefeaa70bb871891ac/7d2e5/InstarVision_System_Service_07.avif 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b467ce6abf248cefeaa70bb871891ac/a0b58/InstarVision_System_Service_07.webp 230w", "/en/static/0b467ce6abf248cefeaa70bb871891ac/bc10c/InstarVision_System_Service_07.webp 460w", "/en/static/0b467ce6abf248cefeaa70bb871891ac/ce206/InstarVision_System_Service_07.webp 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b467ce6abf248cefeaa70bb871891ac/81c8e/InstarVision_System_Service_07.png 230w", "/en/static/0b467ce6abf248cefeaa70bb871891ac/08a84/InstarVision_System_Service_07.png 460w", "/en/static/0b467ce6abf248cefeaa70bb871891ac/898f6/InstarVision_System_Service_07.png 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b467ce6abf248cefeaa70bb871891ac/898f6/InstarVision_System_Service_07.png",
              "alt": "InstarVision 2.7 as a Windows Service",
              "title": "InstarVision 2.7 as a Windows Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`An overview over all NSSM commands can be found in the CLI help:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Usage: nssm `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`option`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`args`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

To show `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` installation GUI:

        nssm `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

To `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` a `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` without confirmation:

        nssm `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`app`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`args`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

To show `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` editing GUI:

        nssm edit `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

To retrieve or edit `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` parameters directly:

        nssm get `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`parameter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`subparameter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

        nssm `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`parameter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`subparameter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

        nssm reset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`parameter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`subparameter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

To show `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` removal GUI:

        nssm remove `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

To remove a `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` without confirmation:

        nssm remove `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` confirm

To manage a service:

        nssm start `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

        nssm stop `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

        nssm restart `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

        nssm status `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

        nssm rotate `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`servicename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      